<template>
  <div id="T-learning" class="container">
    <p>Guess the meaning of</p>
    <div>
      <TLquestion v-bind="randomTL" />
    </div>
  </div>
</template>

<script>
import TLquestion from "@/components/TLQuestion.vue";

export default {
  name: "Tlearning",
  components: {
    TLquestion
  },
  computed: {
    randomTL() {
      var rand = Math.floor(Math.random() * 4);
      return this.Tlearning_Items[rand];
    }
  },

  beforeMount: function() {
    this.Tlearning_Items = [
      {
        idiom: "to miss the memo",
        audio_src:
          "https://www.claramedia.com/IMG/mp3/to-miss-the-memo-rashida.mp3",
        img: {
          src: "https://www.claramedia.com/IMG/png/miss_the_memo.png",
          pos: "-1340px -100px",
          witdh: "200px",
          height: "166px"
        },
        answers: [
          { answer: "to go it alone" },
          { answer: "to be uninformed", isCorrect: true },
          { answer: "to be behind schedule" }
        ],
        more: [
          {
            title: "Explanation",
            body:
              '"<b>to miss the memo</b>" means to be unaware of the current state of affairs, to be or to remain uninformed, especially about something that is common knowledge to everyone else. You can also use the phrase "<b>to get the memo</b>".'
          },
          {
            title: "French translation",
            body:
              "<i>rater le film, louper un épisode</i><br/> How NOT to translate in French : *<i>manquer le mémo</i>"
          },
          {
            title: "Examples in the press",
            body:
              "'Google Responds To Play Store Lawsuit, Makes Some Good Points </br></br> <b>In case you missed the memo</b>, Google is being sued by 36 US states over the Play Store. It didn’t take Google long to respond to that Play Store antitrust lawsuit, as it issued a response on its official website. </br></br> The response was issued by Wilson White, Google’s Senior Director of Public Policy. He does make some good points in his response. The lawsuit says Google is using “anticompetitive tactics” in order to “diminish and disincentivize competition in Android app distribution”.’",
            source: "Android Headlines, 9 July 2021"
          },
          {
            title: "Examples in the press",
            body:
              "'San Jose residents defy orders, light fireworks</br></br> San Jose residents lit up the night sky on Independence Day, ignoring weeks of pleas and threats from city and county officials to not set off illegal fireworks. (...) “This went on for hours last night,” Ngo wrote in a tweet. “<b> Apparently, all of San Jose missed the memo that we are in a drought & fire season and fireworks are illegal.</b>”’",
            source: "San José Spotlight, 6 July 2021"
          },
          {
            title: "Everyday usage",
            body:
              "Why is this guy going ‘tsk tsk’ ? <b>Did I miss the memo that</b> it’s uncool to recline your seat on a short-haul flight ? </br> </br> Littering is plain bad and disrespectful. <br>Did some smokers not get the memo<b/> ?"
          }
        ]
      },
      {
        idiom: "monkey buisness",
        audio_src: "https://www.claramedia.com/IMG/mp3/monkey_business.mp3",
        img: {
          src:
            "https://www.claramedia.com/local/cache-vignettes/L295xH294/monkey_business_2-2-4b9c3.jpg?1623348052",
          pos: "-103px -48px",
          width: "79px",
          height: "30px"
        },
        answers: [
          { answer: "smart buisness" },
          { answer: "deceitful business", isCorrect: true },
          { answer: "worthless business" }
        ],
        more: [
          {
            title: "Explanation",
            body:
              "<b>'monkey business'</b> means trickery, deceitful behaviour. The phrase also refers to silly or frivolous behaviour. </br></br>The phrase has become the title of numerous songs, films, television programmes, etc. </br></br>Among several examples, you may know Chuck Berry’s <i>Too Much Monkey Business</i> or Michael Jackson’s <i>Monkey Business</i>."
          },
          {
            title: "French translation",
            body:
              "<i>manigances, magouille, combine, entourloupe, bêtises</i></br></br>How NOT to translate : *<i>du business de singe</i>"
          },
          {
            title: "Examples in the press",
            body:
              "'Taganak Mayor warns no more <b>monkey business</b> </br></br>KOTA KINABALU : Turtle Islands (Taganak) Mayor, Mohd Faizal Jamalul, has ordered policemen and soldiers to neutralise those involved in heinous crimes, especially the kidnap-for-ransom Abu Sayyaf Group and ISIS terrorists, if spotted in his municipality. </br></br>Irresponsible and thoughtless individuals transporting undocumented Filipinos to Sabah, including those involved in any sort of smuggling activities, especially dangerous drugs, as well as outlaws using the municipality as a jump-off point will be outrightly arrested without much questioning and subsequently turned over to the local authorities.</br></br>….</br></br>“I will never tolerate 'monkey business' for as long as I am the mayor of the town...do it in some other places if you (unwanted people) wish to continue to be freemen or live longer,” he warned.’",
            source: "Daily Express, 12 August 2019"
          },
          {
            title: "Examples in the press",
            body:
              "'<b>Monkey business</b> : British woman, 46, is mugged by a BABOON while on a relaxing seaside walk with her fiance in South Africa</br></br>This is the tense moment a baboon chases down and mugs a woman who was on a relaxing seaside walk on a beautiful South African beach.</br>Mark Bune, 58, and his fiancée Sian Elston-Lowe, 46, were hiking along Kogel Bay Beach in Cape Town, hoping the dolphins and whales would be visible in the clear blue waters on July 21.</br></br>Mr Bune spotted a baboon following them but said he was not ’too concerned’, until roughly half an hour later when the animal ran up to Miss Elston-Lowe from behind.’",
            source: "Mail online, 24 July 2019"
          },
          {
            title: "Everyday usage",
            body:
              "The substitute teacher put an end very quickly to all the <b>monkey business</b>.</br></br>One had to be careful when dealing with the hawkers in the market, who were famous for their <b>monkey business</b>.</br></br><b>No monkey business</b> during fasting, Stanley."
          }
        ]
      },
      {
        idiom: "on the fly",
        audio_src:
          "https://www.claramedia.com/IMG/mp3/on-the-fly-rashida_1.mp3",
        img: {
          src: "https://www.claramedia.com/IMG/png/on_the_fly.png",
          pos: "-40px -300px",
          width: "337px",
          height: "342px"
        },
        answers: [
          { answer: "premeditated" },
          { answer: "in an elegant manner" },
          { answer: "unplanned", isCorrect: true }
        ],
        more: [
          {
            title: "Explanation",
            body:
              "If you do something <b>on the fly</b>, you do it in a quick and informal way, without paying too much attention, while you are doing something else. The phrase refers to something created when needed, something that was not planned ahead. In other words it is something you do on the run."
          },
          {
            title: "French translation",
            body:
              "<i>à la volée, sur le pouce</i></br></br>How NOT to translate : *<i>sur la mouche</i>"
          },
          {
            title: "Examples in the press",
            body:
              "‘Rebuilding America : Abilene car dealership adjusts <b>on the fly</b> to coronavirus outbreak. Sometimes, a crisis leads to creativity and progress.</br>The coronavirus pandemic, for example, forced Arrow Ford to jump ahead a bit in the digital world.</br>The Abilene car dealership already had signed on with a digital marketing plan before COVID-19. But when the outbreak forced a nearly two-month limited local shelter-in-place order, Arrow had to leap headfirst into the digital world. Now, you can shop and buy a car without leaving home.’",
            source: "Abilene Reporter News, 28 May 2020"
          },
          {
            title: "Examples in the press",
            body:
              "‘Grocery stores that adjusted <b>on the fly</b> don’t face the same reopening challenges as others.</br>Supermarket chains and other grocery retailers in Arizona aren’t preparing for the reopening of the economy like a lot of other businesses. That’s because food stores already had to make significant adjustments on the run, as they never shut down during the coronavirus outbreak.’",
            source: "Arizona Republic, 28 May 2020"
          },
          {
            title: "Everyday usage",
            body:
              "On my way home, I’ll try to buy us something to eat <b>on the fly</b>.</br></br>Thar’s impressive : she wrote her book <b>on the fly</b>, in two days only."
          }
        ]
      },
      {
        idiom: "to be on the rocks",
        audio_src:
          "https://www.claramedia.com/IMG/mp3/to_be_on_the_rocks_fabienne.mp3",
        img: {
          src: "https://www.claramedia.com/IMG/png/on_the_rocks.png",
          pos: "-1368px -1195px",
          width: "106px",
          height: "80px"
        },
        answers: [
          { answer: "reaching a safe outcome" },
          { answer: "nice and chilled" },
          { answer: "likely to fail soon", isCorrect: true }
        ],
        more: [
          {
            title: "Explanation",
            body:
              "if something such as a marriage or a business is <b>on the rocks</b>, it is experiencing very severe difficulties and looks likely to end very soon"
          },
          {
            title: "French translation",
            body:
              "<i>au bord du gouffre, voué à l’échec</i></br></br>How NOT to translate : *<i>sur les rochers</i>"
          },
          {
            title: "Examples en the press",
            body:
              "‘Neighbours’ relationship in Cape Town coastal enclave <b>is on the rocks</b>, court papers reveal</br></br>The tranquillity of a Cape Town coastal community has been rocked by a high court confrontation between a hotelier and a restaurant company owned by a Johannesburg lawyer.</br></br>Advocate Andre Bezuidenhout, who owns On the Rocks in Bloubergstrand, has applied for an interdict against Jerry Finley of the neighbouring Pinard on Sea guest house after a conflict which has lasted several years.’",
            source: "Times Live, 13 March 2021"
          },
          {
            title: "Examples in the press",
            body:
              "‘The Marriage Between Republicans and Big Business <b>Is on the Rocks</b> </br>But the internal contradictions of “woke capitalism” are a mixed blessing for the Democratic Party.</br></br>“Woke capitalism” has been a steadily growing phenomenon over the past decade. The muscle of the movement was evident as early as 2015 in Indiana and 2016 in North Carolina, when corporate opposition forced Republicans to back off anti-gay and anti-transgender legislation.</br></br>Much to the dismay of the right — a recent Fox News headline read “Corporations fear woke left minority more than silent majority” — the movement has been gaining momentum, obscuring classic partisan allegiances in corporate America.",
            source: "The New York Times, 14 April 2021"
          },
          {
            title: "Everyday usage",
            body:
              "Our car industry <b>is on the rocks</b>.</br></br>You need to make sound financial decisions <b>to keep your company from being on the rocks</b>."
          }
        ]
      }
    ];
  }
};
</script>
