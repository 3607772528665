import { render, staticRenderFns } from "./TLQuestion.vue?vue&type=template&id=bf633288&scoped=true&"
import script from "./TLQuestion.vue?vue&type=script&lang=js&"
export * from "./TLQuestion.vue?vue&type=script&lang=js&"
import style0 from "./TLQuestion.vue?vue&type=style&index=0&id=bf633288&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf633288",
  null
  
)

export default component.exports