<template>
  <div id="TL_question">
    <h3 class="text-center">{{ idiom }}</h3>

    <audio v-bind:src="audio_src" controls>
      <!--source src="@/assets/audio/don_t_push_your_luck.mp3" type="audio/mpeg" /-->
      Your browser does not support the audio element.
    </audio>

    <div id="answers">
      <TLanswer
        v-for="(answer, id) in answers"
        v-bind:key="id"
        v-bind:isCorrect="answer.isCorrect"
        v-bind:done="done"
        v-on:correct-found="correctFound"
        v-model="answer.isKnown"
      >
        {{ answer.answer }}
      </TLanswer>
    </div>

    <Flipper v-bind:flip-key="flipKey" class="text-center">
      <Flipped flip-id="TLimage">
        <img
          v-bind:src="img.src"
          class="TLimage"
          v-bind:class="TLimageClass"
          v-bind:style="cssVars"
        />
      </Flipped>
    </Flipper>
    <transition name="bounce">
      <div v-if="done">
        <h5 id="well_done" class="text-center">
          Well done! That's the right answer.
        </h5>

        <div id="accordion">
          <div v-for="(part, id) in more" v-bind:key="id">
            <div class="card">
              <div class="card-header" v-bind:id="'header' + id">
                <div>
                  <button
                    class="btn btn"
                    data-toggle="collapse"
                    :data-target="'#collapse' + id"
                    aria-expanded="false"
                    :aria-controls="'collapse' + id"
                  >
                    <h4 class="mb-0" v-html="part.title"></h4>
                  </button>
                </div>
              </div>
              <div
                :id="'collapse' + id"
                class="collapse"
                :aria-labelledby="'header' + id"
                data-parent="#accordion"
              >
                <div class="card-body">
                  <div class="text-justify" v-html="part.body"></div>
                  <div
                    class="mt-2"
                    v-html="part.source"
                    v-if="part.source"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import TLanswer from "@/components/TLAnswer.vue";
import { Flipper, Flipped } from "vue-flip-toolkit";

export default {
  name: "TLquestion",
  components: {
    TLanswer,
    Flipper,
    Flipped
  },
  data() {
    return {
      done: false
    };
  },
  props: ["idiom", "answers", "more", "audio_src", "img"],
  methods: {
    correctFound: function() {
      this.done = true;
      this.answers.forEach(function(answer) {
        answer.isKnown = true;
      });
    },
    getSrc: function() {
      return this.audio_src;
    }
  },
  computed: {
    TLimageClass() {
      return this.done ? "expanded" : "";
    },
    flipKey() {
      return this.done.toString();
    },
    cssVars() {
      return {
        "--object-position": this.img.pos,
        "--width": this.img.width,
        "--height": this.img.height
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import "./style/animations.scss";
#TL_question {
  text-align: center;
  & > * {
    text-align: left;
  }
  h3 {
    margin-bottom: 0;
  }
  audio {
    margin: 1em;
  }
  .centered {
    text-align: center;
  }
}
#answers {
  text-align: center;

  button,
  a {
    @extend .col-sm-8;
    @extend .py-3;
    @extend .btn;
    @extend .border;
    @extend .rounded;
    margin-bottom: 0.5em;
  }

  & .success {
    @extend .bg-primary;
    @extend .text-light;
    &:after {
      content: " ✔";
    }
    @extend .bounce-enter-active;
  }

  & .fail {
    @extend .text-danger;
    @extend .disabled;
    &:after {
      content: " ✘";
    }
    @extend .shake-enter-active;
  }
}
#menubtn {
  @extend .btn;
  @extend .bg-primary;
  @extend .rounded;
  @extend .py-3;
  color: white;
}
.TLimage {
  margin: auto;
  width: var(--width);
  height: var(--height);
  object-fit: none;
  //a definir en fonction de la mignature souhaitée
  object-position: var(--object-position);
}
.TLimage.expanded {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: 0 0;
}
#well_done {
  margin: 1em;
}
</style>
